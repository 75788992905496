var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/mytalk/create"}},[_vm._v("新しいマイトークを作成")]):_vm._e()],1),(_vm.myRoleSettings.get)?_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.headers,"items":_vm.chatRooms.chatRooms,"items-per-page":-1,"loading":_vm.chatRooms.isLoading,"loader-height":"2","hide-default-footer":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"item.chatRoomStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:{
              primary: item.chatRoomStatus === 'active',
              warning: item.chatRoomStatus === 'scheduled',
              error: item.chatRoomStatus === 'deleted',
            },attrs:{"small":""}},[(item.chatRoomStatus === 'active')?_c('span',[_vm._v("公開中")]):(item.chatRoomStatus === 'scheduled')?_c('span',[_vm._v("公開予約")]):(item.chatRoomStatus === 'archive')?_c('span',[_vm._v("公開終了")]):(item.chatRoomStatus === 'deleted')?_c('span',[_vm._v("削除済み")]):_vm._e()])]}},{key:"item.title",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'MyTalkUsers', params: { chatroomId: item.chatRoomId } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.accessDate",fn:function(ref){
            var item = ref.item;
return [(item.accessStartDate.value && item.accessEndDate.value)?_c('div',[_vm._v(" "+_vm._s(item.accessStartDate.dateTime)+" 〜 "+_vm._s(item.accessEndDate.dateTime)+" ")]):(item.accessStartDate.value)?_c('div',[_vm._v(_vm._s(item.accessStartDate.dateTime)+" 〜")]):(item.createDate.value)?_c('div',[_vm._v(_vm._s(item.createDate.dateTime)+" 〜")]):(item.accessEndDate.value)?_c('div',[_vm._v("〜 "+_vm._s(item.accessEndDate.dateTime))]):_vm._e()]}},{key:"item.target",fn:function(ref){
            var item = ref.item;
return [(item.target === 'ユーザー指定')?_c('div',[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-multiple")]),_c('router-link',{attrs:{"to":{
                name: 'MyTalkUsers',
                params: { chatroomId: item.chatRoomId },
              }}},[_vm._v(_vm._s(item.target))])],1):_c('div',{domProps:{"textContent":_vm._s(item.target)}})]}},{key:"item.officialUser",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"fc-official"},[_c('div',{staticClass:"fc-official__icon"},[_c('fc-static-image',{staticStyle:{"width":"50px","height":"50px"},attrs:{"image":_vm.officialUsers.getOfficialUserIcon(item.officialUserId)}})],1),_c('div',{staticClass:"fc-official__name"},[_vm._v(" "+_vm._s(_vm.officialUsers.getOfficialUserName(item.officialUserId))+" ")])])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.myRoleSettings.createAndUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":item.chatRoomStatus === 'archive',"to":{
                  name: 'MyTalkEdit',
                  params: { chatroomId: item.chatRoomId },
                  query: { title: item.title },
                }}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-note-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("編集")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":item.chatRoomStatus !== 'active',"href":(_vm.hostingUrl + "/#/chatroom/" + (item.chatRoomId)),"target":"_blank","rel":"noopener noreferrer"}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v("別タブでページを表示")])]),(_vm.myRoleSettings.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){return _vm.deleteChatRoom(item.chatRoomId, item.title)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e()]}}],null,true)})],1):_c('fc-role-deny')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }