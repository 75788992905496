








































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNotification } from '@/composition/notification';
import { useChatRooms } from '@/composition/myTalk';
import { useConfirm } from '@/composition/confirm';
import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import officialUsers from '@/composition/officialUser';

export default defineComponent({
  name: 'MyTalks',
  components: {
    FcRoleLoading,
    FcStaticImage,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('mytalks'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const chatRooms = useChatRooms();

    const headers = [
      { text: 'ステータス', value: 'chatRoomStatus' },
      { text: 'タイトル', value: 'title' },
      { text: '公開期間', value: 'accessDate', width: '180px' },
      { text: '公開範囲', value: 'target', width: '180px' },
      { text: '公式ユーザー', value: 'officialUser', width: '180px' },
      { text: '', value: 'actions', width: '210px', sortable: false, align: 'end' },
    ];

    // 削除
    const deleteChatRoom = async (chatRoomId: string, title: string) => {
      if (!(await confirmDialog(`本当に「${title}」を削除しますか？`))) return;
      try {
        await chatRooms.deleteChatRoom(chatRoomId);
        await chatRooms.getChatRooms();
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
    };

    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    return {
      pageTitle: 'マイトーク',
      myRoleSettings,
      hostingUrl: core.hostingUrl,
      headers,
      chatRooms,
      officialUsers,
      deleteChatRoom,
      tableHeight,
    };
  },
});
